<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>客服系统</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="buttons">
      房间ID：<el-input class="roomId" type="text" style="width: 200px;" placeholder="请输入房间ID" maxlength="40" v-model="roomId"/>
      <el-button class="joinBtn" type="button" @click="joinRoom" style="margin-left: 10px;">加入房间</el-button>
      <el-button class="leaveBtn" type="button" @click="leaveRoom">离开房间</el-button>
    </div>
    <div class="videos">
      <video ref="localVideo" autoplay muted playsinline>本地窗口</video>
    </div>
    <div ref="remoteVideos"></div>

    <div class="audios" v-show="onlyAudio">
      <audio ref="remoteAudio" autoplay muted controls>播放麦克风的声音</audio>
    </div>
    <div v-show="inRoomFlag">
      <input id="sendMsg" type="text" v-model="wordMessage" @keyup.enter="sendWordMessage"/>
      <button id="submitBtn" @click="sendWordMessage">发送</button>
    </div>
    <div class="chat-message" v-for="message in messages" :key="message.id">
      <div class="message-content">{{ message.content }}</div>
    </div>
  </div>
</template>
<script>

// 信令类型
// join 主动加入房间
// resp-join 加入者加入房间时,告知加入者,房间内已经存在了谁
// leave 主动离开房间
// new-peer 有人加入房间，通知已经在房间的人
// peer-leave 有人离开房间，通知已经在房间的人
// offer 发送offer给对端peer
// answer 发送answer给对端peer
// candidate 发送candidate给对端peer
const SIGNAL_TYPE_JOIN = "join";
const SIGNAL_TYPE_RESP_JOIN = "resp-join";
const SIGNAL_TYPE_LEAVE = "leave";
const SIGNAL_TYPE_NEW_PEER = "new-peer";
const SIGNAL_TYPE_PEER_LEAVE = "peer-leave";
const SIGNAL_TYPE_OFFER = "offer";
const SIGNAL_TYPE_ANSWER = "answer";
const SIGNAL_TYPE_CANDIDATE = "candidate";
// word发送文字
const SIGNAL_TYPE_WORD = "word";
const SIGNAL_TYPE_WORD_MESSAGE = "message";
const SIGNAL_TYPE_WORD_ENTER = "enter";
const SIGNAL_TYPE_WORD_LEAVE = "leave";


export default {
  name: 'customerServiceSystem',
  data() {
    return {
      roomId: '',
      localStream: null,
      remoteStream: null,
      webSocket: null,
      localUserId: '',
      remoteUserId: '',//暂无作用，24-12-29修改
      pc: null,
      onlyAudio: false,
      wordMessage: '',
      messages: [],
      inRoomFlag: false, // 未加入房间不显示文字输入框
      remoteUserIds: [], // 保存房间内都有谁
      remoteMedioObjectList: [], //远程流信息集合，包括远程uid，远程流，赋值在哪个video
      peers: {}, //存储每个peer的视频流
      pcMap: {}, //存储pc
      addMap: {},//保存添加远端记录
      addedDiv: []//记录添加的子div
    }
  },
  mounted() {
    this.pcMap = new Map();
    this.addMap = new Map();
    this.localUserId = Math.random().toString(36).substring(2);
    this.initWebSocket();
  },
  methods: {
    sendWordMessage() {
      console.log('this.wordMessage',this.wordMessage)
      if (this.wordMessage) {
        var jsonMsg = {
              'cmd': SIGNAL_TYPE_WORD,
              'roomId': this.roomId,
              'uid': this.localUserId,
              'msg': JSON.stringify(this.wordMessage),
              'type': SIGNAL_TYPE_WORD_MESSAGE
          };
          var message = JSON.stringify(jsonMsg);
          this.webSocket.send(message);
          console.info("sendWordMessage message: " + message);
          this.wordMessage = '';
      }
    },

     initWebSocket() {
      // 初始化ws
      this.webSocket = new WebSocket("wss://cpsapi.daoyigame.com/webrtc/" + this.localUserId);
      // this.webSocket = new WebSocket("ws://192.168.2.41:8991/webrtc/" + this.localUserId);
      // ws连接建立时触发
      this.webSocket.addEventListener('open', this.wsOpenHanler)
      // ws服务端给客户端推送消息
      this.webSocket.addEventListener('message', this.wsMessageHanler)
      // ws通信发生错误时触发
      this.webSocket.addEventListener('error', this.wsErrorHanler)
      // ws关闭时触发
      this.webSocket.addEventListener('close', this.wsCloseHanler)
    },
    wsOpenHanler(event) {
      console.log("websocket open");
    },
    wsErrorHanler(event) {
      console.log("onError: " + event.data);
    },
    wsCloseHanler(event) {
      console.log("onClose -> code: " + event.code + ", reason: " + EventTarget.reason);
    },
    wsMessageHanler(event) {
      console.log("onMessage: " + event.data);
        var jsonMsg = null;
        try {
            jsonMsg = JSON.parse(event.data);
        } catch(e) {
            console.warn("onMessage parse Json failed:" + e);
            return;
        }
        switch(jsonMsg.cmd) {
            case SIGNAL_TYPE_NEW_PEER:
                this.handleRemoteNewPeer(jsonMsg);
                break;
            case SIGNAL_TYPE_RESP_JOIN:
                this.handleResponseJoin(jsonMsg);
                break;
            case SIGNAL_TYPE_PEER_LEAVE:
                this.handleRemotePeerLeave(jsonMsg);
                break;
            case SIGNAL_TYPE_OFFER:
                this.handleRemoteOffer(jsonMsg);
                break;
            case SIGNAL_TYPE_ANSWER:
                this.handleRemoteAnswer(jsonMsg);
                break;
            case SIGNAL_TYPE_CANDIDATE:
                this.handleRemoteCandidate(jsonMsg);
                break;
            case SIGNAL_TYPE_WORD:
                this.handleWord(jsonMsg);
                break;
        }
    },
    // handleRemoteNewPeer + handleResponseJoin方法中remoteUserIds能得到房间内所有其他人的uid
    handleRemoteNewPeer(message) {
      console.info("handleRemoteNewPeer, remoteUid:"+message.remoteUid);
      this.remoteUserId = message.remoteUid;
      this.remoteUserIds.push(message.remoteUid);
      this.doOffer(message.remoteUid);
    },
    handleResponseJoin(message) {
      console.info("handleResponseJoin, remoteUid:"+message.remoteUid);
      this.remoteUserId = message.remoteUid;
      // 自己加入房间时，得到并保存房间内其他人的uid
      this.remoteUserIds.push(message.remoteUid);
    },
    handleRemotePeerLeave(message) {
      console.info("handleRemotePeerLeave, remoteUid:"+message.remoteUid);
      // 1、不显示对方
      this.$refs.remoteVideos.removeChild(this.peers[message.remoteUid].video);
      this.$refs.remoteAudio.srcObject = null;
      let pc = this.pcMap.get(this.localUserId + "_" + message.remoteUid);
      if (pc != null) {
        pc.close();
        pc = null;
        this.pcMap.delete(this.localUserId + "_" + message.remoteUid);
      }
    },
    handleRemoteOffer(message) {
      console.info("handleRemoteOffer message", message);
      this.remoteUserId = message.uid;
      let pc = this.pcMap.get(this.localUserId+"_"+message.uid);
      console.info("handleRemoteOffer pc", pc);
      if (pc == null || pc == undefined) {
          this.createPeerConnection(message.uid);
          pc = this.pcMap.get(this.localUserId+"_"+message.uid);
      }
      var desc = JSON.parse(message.msg);
      pc.setRemoteDescription(desc);
      this.doAnswer(pc, message.uid);
    },
    handleRemoteAnswer(message) {
      console.info("handleRemoteAnswer message", message);
      let pc = this.pcMap.get(this.localUserId+"_"+message.uid);
      var desc = JSON.parse(message.msg);
      pc.setRemoteDescription(desc);
    },
    handleRemoteCandidate(message) {
      console.info("handleRemoteCandidate");
      let pc = this.pcMap.get(this.localUserId+"_"+message.uid);
      var candidate = JSON.parse(message.msg);
      pc.addIceCandidate(candidate).catch(e => {
          console.error("addIceCandidate failed:" + e.name);
      });
    },
    handleWord(message) {
      console.info("handleWord：",message);
      var uid = message.uid;
      var type = message.type;
      const id = Date.now();
      if (SIGNAL_TYPE_WORD_ENTER == type) {
        this.messages.push({ id, content: uid +' 加入房间' });
      } else if (SIGNAL_TYPE_WORD_LEAVE == type) {
        if (uid != this.localUserId) {
          this.messages.push({ id, content: uid +' 离开房间' });
        }
        
      } else {
        var message = JSON.parse(message.msg);
        this.messages.push({ id, content: uid +' 说：'+message });
      }
    },

    doOffer(remoteUid) {
      // 创建RTCPeerConnnection
      let pc = this.pcMap.get(this.localUserId+"_"+remoteUid);
      if (pc == null || pc == undefined) {
        // 本地TO远端的peer若不存在，则创建peer实例
        this.createPeerConnection(remoteUid);
        pc = this.pcMap.get(this.localUserId+"_"+remoteUid);
      }
      // pc.createOffer().then(this.createOfferAndSendMessage).catch(this.handleCreateOfferError);
      pc.createOffer().then((session)=>{
        pc.setLocalDescription(session)
        .then(() => {
            var jsonMsg = {
                'cmd': SIGNAL_TYPE_OFFER,
                'roomId': this.roomId,
                'uid': this.localUserId,
                'remoteUid': remoteUid,
                'msg': JSON.stringify(session)
            };
            var message = JSON.stringify(jsonMsg);
            this.webSocket.send(message);
            console.info("createOfferAndSendMessage message: " + message);
        })
        .catch(error => {
            console.error("offer setLocalDescription failed:" + error);
        });
      }
      ).catch(this.handleCreateOfferError);
    },
    doAnswer(pc, remoteUid) {
      // pc.createAnswer().then(this.createAnswerAndSendMessage).catch(this.handleCreateAnswerError);
      pc.createAnswer().then((session)=>{
        pc.setLocalDescription(session)
        .then(() => {
            var jsonMsg = {
                'cmd': SIGNAL_TYPE_ANSWER,
                'roomId': this.roomId,
                'uid': this.localUserId,
                'remoteUid': remoteUid,
                'msg': JSON.stringify(session)
            };
            var message = JSON.stringify(jsonMsg);
            this.webSocket.send(message);
            console.info("createAnswerAndSendMessage message: " + message);
        })
        .catch(error => {
            console.error("answer setLocalDescription failed:" + error);
        });
      }
      ).catch(this.handleCreateAnswerError);
      
    },
    createPeerConnection(remoteUid) {
      var defaultConfiguration = {
        dundlePolicy: "max-bundle",
        rtcpMuxPolicy: "require",
        iceTransportPolicy: "relay",//relay或者all，all允许p2p(即内网测不出是否能在公网访问，所以测试环境测试时最好使用relay)，而relay只有中继的模式
        iceServers: [
            {
                "urls": [
                    "turn:203.3.112.162:3478?transport=udp",
                    "turn:203.3.112.162:3478?transport=tcp" //可以插入多个进行备选
                ],
                "username": "admin",
                "credential": "123456"
            },
            {
                "urls": [
                    "stun:203.3.112.162:3478"
                ]
            }
        ]
      }
      let pc = new RTCPeerConnection(defaultConfiguration);

      const key = this.localUserId+"_"+remoteUid;
    
      pc.onicecandidate = event => {
        console.info("handleIceCandidate:",event);
        if (event.candidate) {
            var jsonMsg = {
                'cmd': SIGNAL_TYPE_CANDIDATE,
                'roomId': this.roomId,
                'uid': this.localUserId,
                'remoteUid': remoteUid,
                'msg': JSON.stringify(event.candidate)
            };
            var message = JSON.stringify(jsonMsg);
            this.webSocket.send(message);
            console.info("handleIceCandidate message: " + message);
        } else {
            console.warn("End of candidates");
        }
      };
      pc.ontrack = event => {
        const addRecord = this.addMap.get(key);
        console.info("handleRemoteStreamAdd addRecord1",addRecord);
        if (addRecord != null || addRecord != undefined) {
          // 防止重复Add
          return;
        }
        this.addMap.set(key, 1);

        const remoteStream = event.streams[0];
        this.remoteStream = remoteStream;
        if (this.onlyAudio) {
          this.$refs.remoteAudio.srcObject = this.remoteStream;
        } else {
          const video = document.createElement("video");
          video.autoplay = true;
          video.srcObject = remoteStream;// 关联流
          video.id = 'video-' + remoteUid
          console.log('video.id:',video.id);
          this.$refs.remoteVideos.appendChild(video); 
          this.peers[remoteUid] = {video, remoteStream};
          this.addedDiv.push(video);
        }
      }
      this.pcMap.set(key, pc);

      pc.onconnectionstatechange = this.handleConnectionStateChange;
      pc.oniceconnectionstatechange = this.handleIceConnectionStateChange;

      // 遍历本地码流，放置到PC的通道内track
      this.localStream.getTracks().forEach((track) => pc.addTrack(track,this.localStream));
    },
    handleIceCandidate(event) {
      console.info("handleIceCandidate");
      if (event.candidate) {
          var jsonMsg = {
              'cmd': SIGNAL_TYPE_CANDIDATE,
              'roomId': this.roomId,
              'uid': this.localUserId,
              'remoteUid': this.remoteUserId,
              'msg': JSON.stringify(event.candidate)
          };
          var message = JSON.stringify(jsonMsg);
          this.webSocket.send(message);
          console.info("handleIceCandidate message: " + message);
      } else {
          console.warn("End of candidates");
      }
    },

    handleRemoteStreamAdd(event) {
      console.info("handleRemoteStreamAdd",this.localUserId+"_"+this.remoteUserId);
      const addRecord = this.addMap.get(this.localUserId+"_"+this.remoteUserId);
      console.info("handleRemoteStreamAdd addRecord",addRecord);
      if (addRecord != null || addRecord != undefined) {
        console.info("handleRemoteStreamAdd return",'return');
        // 防止重复Add
        return;
      }
      this.addMap.set(this.localUserId+"_"+this.remoteUserId, 1);
      const remoteStream = event.streams[0];
      this.remoteStream = remoteStream;
      if (this.onlyAudio) {
        this.$refs.remoteAudio.srcObject = this.remoteStream;
      } else {
        const video = document.createElement("video");
        video.autoplay = true;
        video.srcObject = remoteStream;// 关联流
        this.$refs.remoteVideos.appendChild(video); 
        this.peers[this.remoteUserId] = {video, remoteStream};
      }
    },
    handleConnectionStateChange() {
      const pc = this.pcMap.get(this.localUserId+"_"+this.remoteUserId);
      if (pc != null && pc != undefined) {
        console.info("ConnectionState -> " + pc.connectionState);
      }
    },
    handleIceConnectionStateChange() {
      const pc = this.pcMap.get(this.localUserId+"_"+this.remoteUserId);
      if (pc != null && pc != undefined) {
        console.info("IceConnectionState -> " + pc.iceConnectionState);
      }
    },

    createOfferAndSendMessage(session) {
      this.pcMap.get(this.localUserId+"_"+this.remoteUserId).setLocalDescription(session)
        .then(() => {
            var jsonMsg = {
                'cmd': SIGNAL_TYPE_OFFER,
                'roomId': this.roomId,
                'uid': this.localUserId,
                'remoteUid': this.remoteUserId,
                'msg': JSON.stringify(session)
            };
            var message = JSON.stringify(jsonMsg);
            this.webSocket.send(message);
            console.info("createOfferAndSendMessage message: " + message);
        })
        .catch(error => {
            console.error("offer setLocalDescription failed:" + error);
        });
    },
    handleCreateOfferError(error) {
      console.error("handleCreateOfferError:" + error);
    },
    createAnswerAndSendMessage(session) {
      this.pcMap.get(this.localUserId+"_"+this.remoteUserId).setLocalDescription(session)
        .then(() => {
            var jsonMsg = {
                'cmd': SIGNAL_TYPE_ANSWER,
                'roomId': this.roomId,
                'uid': this.localUserId,
                'remoteUid': this.remoteUserId,
                'msg': JSON.stringify(session)
            };
            var message = JSON.stringify(jsonMsg);
            this.webSocket.send(message);
            console.info("createAnswerAndSendMessage message: " + message);
        })
        .catch(error => {
            console.error("answer setLocalDescription failed:" + error);
        });
    },
    handleCreateAnswerError(error) {
      console.error("handleCreateAnswerError:" + error);
    },

    joinRoom() {
      if (this.roomId == "" || this.roomId == "请输入房间ID") {
          alert("请输入房间ID");
          return;
      }
      console.log("加入按钮被点击, roomId:" + this.roomId);
      this.initLocalStream();
      this.enterRoom();
    },

    enterRoom() {
      // 加入房间，显示文字输入框
      this.inRoomFlag = true;
      // 广播消息，提示加入房间了
      var jsonMsg = {
              'cmd': SIGNAL_TYPE_WORD,
              'roomId': this.roomId,
              'uid': this.localUserId,
              'type': SIGNAL_TYPE_WORD_ENTER
          };
          var message = JSON.stringify(jsonMsg);
          this.webSocket.send(message);
          console.info("sendWordMessage enterRoom message: " + message);
    },

    initLocalStream() {
      // 判断设备是否有摄像头或者麦克风
      
      navigator.mediaDevices.enumerateDevices().then(devices => {
        console.log('devices',devices);
        let hasCamera = false;
        let hasMicrophone = false;
        devices.forEach(device => {
            if (device.kind === 'videoinput') {
                hasCamera = true;
            }
            if (device.kind === 'audioinput') {
                hasMicrophone = true;
            }
        });
        console.log('hasCamera',hasCamera);
        console.log('hasMicrophone',hasMicrophone);
        if (!hasCamera && !hasMicrophone) {
          alert("当前电脑未检测到摄像头或麦克风");
          return;
        }
        if (!hasMicrophone) {
          alert("当前电脑未检测到麦克风");
          return;
        }
        if (hasMicrophone && !hasCamera) {
          this.onlyAudio = true;
        }
        navigator.mediaDevices.getUserMedia({
          audio: hasMicrophone,
          video: hasCamera
        })
        .then(this.openLocalStream)
        .catch(e => {
            alert("getUserMedia() error: " + e.name);
        })
      }).catch(error => {
          console.error('Error checking devices:', error);
      });
    },

    openLocalStream(stream) {
      console.log('Open local stream:'+ stream);
      this.doJoin();
      
      this.localStream = stream;
      this.$refs.localVideo.srcObject = stream;
    },

    doJoin() {
      var jsonMsg = {
        'cmd': SIGNAL_TYPE_JOIN,
        'roomId': this.roomId,
        'uid': this.localUserId
      };
      console.info("doJoin jsonMsg: " + jsonMsg);
      var message = JSON.stringify(jsonMsg);
      this.webSocket.send(message);
      console.info("doJoin message: " + message);
    },

    leaveRoom() {
      if (this.inRoomFlag) {
        this.leaveWordRoom();
        this.doLeave();
      } else {
        alert('还未加入房间')
      }
      
    },

    leaveWordRoom() {
      // 离开房间
      this.inRoomFlag = false;
      this.messages = []
      // 广播消息，提示加入房间了
      var jsonMsg = {
              'cmd': SIGNAL_TYPE_WORD,
              'roomId': this.roomId,
              'uid': this.localUserId,
              'type': SIGNAL_TYPE_WORD_LEAVE
          };
          var message = JSON.stringify(jsonMsg);
          this.webSocket.send(message);
          console.info("sendWordMessage leaveRoom message: " + message);
    },

    doLeave() {
      var jsonMsg = {
        'cmd': SIGNAL_TYPE_LEAVE,
        'roomId': this.roomId,
        'uid': this.localUserId
      };
      var message = JSON.stringify(jsonMsg);
      this.webSocket.send(message);
      console.info("doLeave message: " + message);
      // 主动关闭方，关闭时
      this.hangup();
    },
    hangup() {
      this.$refs.localVideo.srcObject = null; // 0、关闭本地
      this.$refs.remoteAudio.srcObject = null;
      // 1、不显示对方
      this.addedDiv.forEach((element)=>{
        this.$refs.remoteVideos.removeChild(element);
      })
      this.onlyAudio = false;
      this.closeLocalStream();           // 2、关闭本地流
      this.peers = {};
      this.addMap = new Map();
      //3、关闭RTCPeerConnection
      for(var key in this.pcMap) {
        if (this.pcMap[key] != null) {
          this.pcMap[key].close();
          this.pcMap[key] = null;
        }
      }
      this.pcMap = new Map();
    },
    closeLocalStream() {
      if (this.localStream != null) {
        this.localStream.getTracks().forEach((track) => {
            track.stop();
        });
      }
    }
  }
}
</script>
